<template>
  <div class="Business">
    <BlockTitle
      :cnName="titleConfig.cnName"
      :enName="titleConfig.enName"
    />
    <div class="content_center_medium Business-content">
      <template v-for="(item, index) in businessOptions">
        <!-- <template v-if="index === 0">
          <div :key="index" class="Business-content-item Business-content-item_top">
            <div class="Business-content-item_top-left" v-lazy:background-image="business_left_bg">
              <div class="top">
                <div class="type">{{ item.type }}</div>
                <div class="title">{{ item.title }}</div>
                <div class="desc">
                  <template v-for="(text, index) in item.desc">
                    <p :key="index" v-html="text"></p>
                  </template>
                </div>
              </div> 
              <div class="bottom">
                <div class="title-en">{{ item.titleEn }}</div>
              </div> 
            </div>
            <div class="Business-content-item_top-right">
              <Charts />
            </div>
          </div>
        </template> -->
        <!-- <template v-else> -->
          <div :key="index" class="Business-content-item">
            <div class="Business-content-item-left" v-lazy:background-image="business_small_bg">
              <div class="top">
                <div class="title-en">{{ item.titleEn }}</div>
              </div> 
              <div class="bottom">
                <div class="type">{{ item.type }}</div>
                <div class="title">{{ item.title }}</div>
                <!-- <div class="sub_title">{{ item.subTitle }}</div> -->
                <div class="desc">
                  <template v-for="(text, index) in item.desc">
                    <p :key="index" v-html="text"></p>
                  </template>
                </div>
              </div> 
            </div>
            <div class="Business-content-item-right">
              <Charts />
            </div>
          </div>
        <!-- </template> -->
      </template>
    </div>
  </div>
</template>

<script>
  import business_left_bg from '@/assets/images/customs/business_left_bg.jpg';
  import business_small_bg from '@/assets/images/customs/business_small_bg.jpg';
  import Charts from './Charts.vue';
  export default {
    name: 'Business',
    components: {
      Charts,
    },
    data() {
      return {
        titleConfig: {
          cnName: '业务类型',
          enName: 'BUSINESS TYPE',
        },
        business_left_bg,
        business_small_bg,
        businessOptions: [
          {
            type: 9610,
            title: '跨境电商进出口业务',
            titleEn: 'E-commerce service platform',
            desc: [
              '9610全称“跨境贸易电子商务”<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;简称“电子商务”<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;俗称“集货模式”',
              '适用于境内个人或电子商务企业通过电子商务交易平台实现交易，并采用“清单核放，汇总申报”模式办理通关手续的电子商务零售进出口商品。主要针对小型货物，即直邮模式。',
              '帮助跨境电商企业实现海关申报、汇总清单和出具报关单，主要提供简化申报、汇总申报等服务。'
            ],
          },
          {
            type: 9710,
            title: '跨境电商出口业务',
            titleEn: 'E-commerce service platform',
            desc: [
              '9710全称“跨境电子商务企业对企业直接出口”<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;简称“跨境电商B2B直接出口”。',
              '适用于境内企业通过跨境电商平台与境外企业达成交易后，通过跨境电商B2B直接出口的货物。国内企业通过跨境电商平台开展线上商品、企业信息展示并与国外企业建立联系，在线上或线下完成沟通、下单、支付、履约流程，实现货物出口的模式。'
            ]
          },
          {
            type: 9810,
            title: '跨境电商出口业务',
            titleEn: 'E-commerce service platform',
            desc: [
              '9810全称“跨境电子商务出口海外仓”<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;简称“跨境电商出口海外仓”',
              '适⽤于境内企业先将货物通过跨境物流出口至海外仓，通过跨境电商平台实现交易后从海外仓送达境外购买者。',
            ]
          },
          {
            type: 1210,
            title: '跨境电商进出口业务',
            titleEn: 'E-commerce service platform',
            desc: [
              '1210全称“保税跨境贸易电子商务”<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;简称“保税电商”<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;俗称“备货模式”',
              '适用于境内个人或电子商务企业在经海关认可的电子商务平台实现跨境交易，并通过海关特殊监管区域或保税监管场所进出的电子商务零售进出境商品。',
            ]
          }
        ],
      }
    }
  }
</script>

<style lang="scss" scoped>
.Business{
  margin: 100px auto;
  &-content{
    // display: flex;
    // flex-wrap: wrap;
    margin-top: 100px;
    // &-item_top{
    //   display: flex;
    //   width: 100%;
    //   margin-bottom: 80px;
    //   box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.1);
    //   &-left{
    //     width: 440px;
    //     height: 820px;
    //     padding: 100px 50px 32px;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: space-between;
    //     box-sizing: border-box;
    //   }
    //   &-right{
    //     flex: 1;
    //   }
    //   &-bottom{

    //   }
    // }
    // &-item_bottom{
    //   width: 440px;
    //   height: 410px;
    //   margin-left: 100px;
    //   padding: 30px 50px 32px;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: space-between;
    //   box-sizing: border-box;
    // }
    &-item{
      height: 410px;
      margin-top: 80px;
      display: flex;
      box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.1);
      &:first-child{
        margin-top: 0;
      }
      &-left{
        width: 440px;
        padding: 30px 50px 32px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title-en{
          font-size: 18px;
          font-weight: 700;
          font-family: DINAlternate-Bold, DINAlternate;
          color: rgba(255, 255, 255, 0.3);
          text-align: center;
        }
        .top{
          .title-en{
            text-align: right;
          }
        }
        .type{
          font-size: 40px;
          font-weight: 700;
          font-family: DINAlternate-Bold, DINAlternate;
          color: #fff;
        }
        .title{
          margin: 15px 0 30px;
          font-size: 20px;
          color: #fff;
        }
        .desc{
          p{
            line-height: 18px;
            margin-bottom: 20px;
            color: #fff;
          }
        }
      }
      &-right{
        flex: 1;
      }
    }
  }
}
</style>