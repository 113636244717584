<template>
  <div class="GoodsCategoryChart">
    <div class="GoodsCategoryChart-top">
      <div class="title">{{ title }}</div>
      <div class="sub_title">{{ subTitle }}</div>
    </div>
    <div
      ref="chartContainer"
      class="chart-container"
    />
  </div>
</template>

<script>
import $echartsConfig from '@/utils/echartsConfig';
export default {
  name: 'GoodsCategoryChart',
  data() {
    return {
      title: '货物品类',
      subTitle: '2021年青岛"9610"模式出口的商品按照海关分类有520多类、1600多种',
      echartsView: null,
      xData: [ '简易低值物品', '假发制品', '服装', '玩具', '箱包' ],
      seriesData: [ 29, 16, 5, 4, 4 ],
      colors: {
        '简易低值物品': '#3484FB',
        '假发制品': '#EAEAEA',
        '服装': '#3484FB',
        '玩具': '#EAEAEA',
        '箱包': '#3484FB',
      },
    };
  },
  mounted () {
    this.initChart();
  },
  methods: {
    initChart() {
      this.echartsView = $echartsConfig.echarts.init(this.$refs.chartContainer);
      this.initChartOption();
    },
    initChartOption() {
      const option = {
        tooltip: $echartsConfig.tooltip,
        grid: {
          ...$echartsConfig.grid,
          right: 30,
        },
        xAxis: {
          type: 'value',
          show: false
        },
        yAxis: {
          ...$echartsConfig.axis,
          type: 'category',
          inverse: true,
          data: this.xData,
        },
        series: [
          {
            type: 'bar',
            barWidth: 20,
            yAxisIndex: 0,
            itemStyle: {
              color: (param) => {
                return this.colors[param.name] || '#3484FB';
              }
            },
            label: {
              show: true,
              position: 'right',
              color: '#33333B',
              formatter: '{c}%',
            },
            data: this.seriesData,
          },
        ],
      };
      this.echartsView.setOption(option, true);
    },
  },
};
</script>

<style lang="scss" scoped>
.GoodsCategoryChart{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &-top{
    height: 50px;
    position: relative;
    .title{
      margin-bottom: 5px;
      font-size: 20px;
      font-weight: 700;
      color: #33333B;
    }
    .sub_title{
      color: #5F5F6A;
    }
  }
  .chart-container {
    flex: 1;
  }
}
</style>
