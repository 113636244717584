<template>
  <div class="Strength" v-lazy:background-image="strength_bg">
    <BlockTitle
      :cnName="titleConfig.cnName"
      :enName="titleConfig.enName"
    />
    <div class="Strength-content">
      <template v-for="(item, index) in list">
        <div :key="index" class="Strength-content-item">
          <div class="number">0{{ index+1 }}</div>
          <div class="text">{{ item }}</div>
        </div>
      </template>
      <div class="Strength-content-inset"><img v-lazy="strength_inset" alt=""></div>
    </div>
  </div>
</template>

<script>
  import strength_bg from '@/assets/images/customs/strength_bg.jpg';
  import strength_inset from '@/assets/images/customs/strength_inset.png';
  export default {
    name: 'Strength',
    data() {
      return {
        strength_bg,
        strength_inset,
        titleConfig: {
          cnName: '业务优势',
          enName: 'BUSINESS ADVANTAGES',
        },
        list: [
          '海关可以适当放宽要求，简化审核过程',
          '跨境电商享受优先发货政策',
          '跨境电商享受税收优惠政策',
          '完善跨境电商进出口⼀体化发展'
        ]
      }
    },
  }
</script>

<style lang="scss" scoped>
.Strength{
  padding: 80px 0;
  &-content{
    width: 980px;
    margin: 80px auto 0;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    &-item{
      width: 480px;
      height: 175px;
      padding-top: 45px;
      padding-left: 56px;
      background: #F1F1F4;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      display: flex;
      &:nth-child(1) ,
      &:nth-child(2) {
        margin-bottom: 20px;
      }
      &:nth-child(odd) {
        padding-right: 110px;
      }
      &:nth-child(even) {
        padding-left: 130px;
        padding-right: 20px;
        margin-left: 20px;
      }
      .number{
        margin-right: 15px;
        font-size: 46px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #3484FB;
      }
      .text{
        font-size: 20px;
        color: #5F5F6A;
      }
    }
    &-inset{
      position: absolute;
      width: 280px;
      height: 325px;
      left: 49%;
      top: 50%;
      transform: translate(-50%, -50%);
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>