<template>
  <div class="Charts">
    <!-- <div class="Charts-item">
      <TotalOutputValueChart />
    </div>
    <div class="Charts-item">
      <ExitGateChart />
    </div> -->
    <div class="Charts-item">
      <TradingNationChart />
    </div>
    <div class="Charts-item">
      <GoodsCategoryChart />
    </div>
  </div>
</template>

<script>
  // import TotalOutputValueChart from './TotalOutputValueChart.vue';
  // import ExitGateChart from './ExitGateChart.vue';
  import TradingNationChart from './TradingNationChart.vue';
  import GoodsCategoryChart from './GoodsCategoryChart.vue';
  export default {
    name: 'Charts',
    components: {
      // TotalOutputValueChart,
      // ExitGateChart,
      TradingNationChart,
      GoodsCategoryChart,
    },
    data() {
      return {
        
      }
    },
  }
</script>

<style lang="scss" scoped>
.Charts{
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  &-item{
    width: 50%;
    height: 410px; 
    padding: 30px 30px;
    box-sizing: border-box;
  }
}
</style>