<template>
  <div class="Customs">
    <SubTopBanner :topBannerUrl="top_banner">
      <HeaderNav :navIndex="2" />
    </SubTopBanner>
    <Business id="anchor_a" />
    <Strength id="anchor_b" />
    <Platform id="anchor_d" />
    <Footer />
  </div>
</template>

<script>
  import HeaderNav from '@/components/HeaderNav.vue';
  import Business from './Business.vue';
  import Strength from './Strength.vue';
  import Platform from './Platform.vue';
  import top_banner from '@/assets/images/customs/top_banner.jpg';
  export default {
    name: 'Customs',
    components: {
      HeaderNav,
      Business,
      Strength,
      Platform,
    },
    data() {
      return {
        top_banner,
      }
    },
    mounted () {
      this.getBanner();
    },
    methods: {
      async getBanner() {
        const res = await this.$request.get(`/ad/data/customs_banner`);
        if(res.data.length) {
          this.top_banner = res.data[0].image;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.Customs{

}
@media screen and (max-width: 1519px) {
  .Customs{
    ::v-deep(.Business-content){
      width: 1280px;
      .Business-content-item_bottom{
        width: 410px;
        margin-left: 24px;
        &.Business-content-item:nth-child(2){
          margin-left: 0;
        }
      }
    }
    ::v-deep(.Platform){
      .Platform-container-bottom{
        width: 1280px;
        .Platform-container-bottom-item{
          width: 630px;
        }
      }
    }
  }
}
</style>