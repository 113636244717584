<template>
  <div class="Platform">
    <BlockTitle
      :cnName="titleConfig.cnName"
      :enName="titleConfig.enName"
      :isDesc="true"
      :descList="descList"
    />
    <div class="Platform-container">
      <div class="Platform-container-top">
        <template v-for="(item, index) in iconList">
          <div :key="index" class="Platform-container-top-item">
            <div class="icon"><img :src="item.icon"></div>
            <div class="name">{{ item.name }}</div>
          </div>
        </template>
      </div>
      <div class="content_center_medium Platform-container-bottom">
        <template v-for="(item, index) in imageTextList">
          <div :key="index" class="Platform-container-bottom-item">
            <div class="img"><img :src="item.url"></div>
            <div class="text" v-lazy:background-image="platform_text_bg">
              <div class="title">{{ item.title }}</div>
              <div class="desc">{{ item.desc }}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import platform_icon_1 from '@/assets/images/customs/platform_icon_1.png';
  import platform_icon_2 from '@/assets/images/customs/platform_icon_2.png';
  import platform_icon_3 from '@/assets/images/customs/platform_icon_3.png';
  import platform_icon_4 from '@/assets/images/customs/platform_icon_4.png';
  import platform_icon_5 from '@/assets/images/customs/platform_icon_5.png';
  import platform_icon_6 from '@/assets/images/customs/platform_icon_6.png';
  import platform_icon_7 from '@/assets/images/customs/platform_icon_7.png';
  import platform_icon_8 from '@/assets/images/customs/platform_icon_8.png';
  import platform_img_1 from '@/assets/images/customs/platform_img_1.jpg';
  import platform_img_2 from '@/assets/images/customs/platform_img_2.jpg';
  import platform_img_3 from '@/assets/images/customs/platform_img_3.jpg';
  import platform_img_4 from '@/assets/images/customs/platform_img_4.jpg';
  import platform_text_bg from '@/assets/images/customs/platform_text_bg.jpg';
  export default {
    name: 'Platform',
    data() {
      return {
        titleConfig: {
          cnName: '跨境电商综合服务平台',
          enName: 'E-COMMERCE SERVICE PLATFORM',
        },
        descList: [
          '据国家跨境电商的引导政策和监管模式，结合市场情况，创新业务形式，搭建完整的综合服务平台，为企业提供跨境电商一揽子解决⽅案。',
        ],
        iconList: [
          { name: '订单申报', icon: platform_icon_1 },
          { name: '收款单申报', icon: platform_icon_2 },
          { name: '运单申报', icon: platform_icon_3 },
          { name: '清单申报', icon: platform_icon_4 },
          { name: '总分单申报', icon: platform_icon_5 },
          { name: '离境单申报', icon: platform_icon_6 },
          { name: '汇总申报', icon: platform_icon_7 },
          { name: '报关单申报', icon: platform_icon_8 },
        ],
        imageTextList: [
          {
            url: platform_img_1,
            title: '政务中心',
            desc: '“政府主导、市场运作、企业经营”的原则，为城阳区提供跨境电商整体解决方案，打通跨境贸易电子商务“关、汇、税、商、物、金融”间的信息壁垒。'
          },
          {
            url: platform_img_2,
            title: '商务中心',
            desc: '建设平台进出口集货、集散仓。打造跨境电商海上快速服务通道，完善中日韩、 东南亚海上快速服务通道的配套集货、分拨中心功能。'
          },
          {
            url: platform_img_3,
            title: '直播+孵化中心',
            desc: '引进直播运营企业，搭建直播运营场地，打造青岛最具规模的⽹红直播园区，聚力直播培训、经纪业务、推广联营、供应链管理、品牌孵化、金融服务六大板块，打造“人、货、场”一体融合的无界零售新平台。'
          },
          {
            url: platform_img_4,
            title: '运营管理中心',
            desc: '提升平台运营及孵化效能，提供海关、税务、外汇和信息流、物流、资金流等一体化服务。'
          }
        ],
        platform_text_bg
      }
    },
  }
</script>

<style lang="scss" scoped>
.Platform{
  margin: 100px auto;
  &-container{
    &-top{
      display: flex;
      flex-wrap: wrap;
      width: 1060px;
      margin: 0 auto;
      &-item{
        width: 100px;
        margin-top: 60px;
        margin-right: 220px;
        &:nth-child(4n){
          margin-right: 0;
        }
        .icon{
          width: 72px;
          height: 72px;
          margin: 0 auto;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .name{
          text-align: center;
          font-size: 20px;
          color: #33333B;
        }
      }
    }
    &-bottom{
      margin-top: 80px;
      display: flex;
      flex-wrap: wrap;
      &-item{
        width: 750px;
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(even){
          margin-right: 0;
        }
        .img{
          width: 100%;
          height: 240px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .text{
          height: 120px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
        .title{
          padding: 20px 0 20px 40px;
          font-size: 20px;
          color: #171717;
        }
        .desc{
          padding: 0 40px;
          line-height: 18px;
          color: #5F5F6A;
        }
      }
    }
  }
}
</style>